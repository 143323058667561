<template>
  <div>
    <template v-for="(display,index) in displayData">
      <div
        v-if="displayData"
        :key="index"
        class= 'row vertical-align'>
        <div
          v-if="display.display"
          class="box box-body box-default" >
          <div  v-if="(!display.invoice.final)" class="box-title final" >
            Zlecenie otwarte
          </div>
          <div  v-if="(display.invoice.duplicated)" v-bind:class="(!display.invoice.final)? 'box-title duplicated final-duplicated' : 'box-title duplicated'">
            Zlecenie zduplikowane
          </div>
          <div class="col-xs-half">
            {{ index + 1 }}.
          </div>
          <div class="col-xs-2 import-invoice-number">
            <template
              v-for="(data, dataIndex) in display.display"
            >
              <div
                v-if="dataIndex === 0"
                :key="`${index}-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class="col-xs-5 current-invoice-data-row"
          >
            <template v-for="(data, dataIndex) in display.display">
              <div
                v-if="dataIndex > 0 && dataIndex < 6"
                :key="`${index}-${dataIndex}`"
                class="current-invoice-data-column"
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class="current-invoice-data-column"
                >
                  <div
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </div>
                </div>
                <div
                  v-if="!Array.isArray(data.value)"
                  class=""
                >
                  {{ data && data.value }}
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="display.display"
            class=" col-xs-3 new-invoice-data-row"
          >
            <template v-for="(data, dataIndex) in display.display">
              <div
                v-if="dataIndex > 5"
                :key="`${index}-${dataIndex}`"
                class=""
              >
                <strong class="">{{ data && data.label }}</strong>
                <div
                  v-if="data && Array.isArray(data.value) && data.value.length > 0"
                  class=""
                >
                  <span
                    v-for="(dateOrNum, dateOrNumIndex) in data.value"
                    :key="`${index}-${dataIndex}-${dateOrNumIndex}`"
                  >
                    {{ dateOrNum }}
                  </span>
                </div>
                <span
                  v-if="!Array.isArray(data.value)"
                  class=""
                >{{ data && data.value }}</span>
              </div>
            </template>
          </div>
            <div class="col-xs-2 invoice-select-align">
              <SelectType
                v-if="!isSummary && !correction"
                v-model="invoiceToOverwrite[index]"
                class=""
                name="invoiceToOverwrite"
                label=""
                :empty-first="true"
                :options="tasksInvoices[display.invoice.number] === undefined ? [] : tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber)"
                :disabled="tasksInvoices[display.invoice.number] === undefined || tasksInvoices[display.invoice.number].filter(el => !el.invoiceNumber).length === 0 || !display.invoice.original"
              />
              <p v-if="isSummary">
                {{ getActionLabel(display.invoice.action) }}
              </p>
            </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SelectType from '../../../share/form/type/SelectType'
import ListMixin from './ListMixin'

export default {
  components: { SelectType },
  mixins: [ListMixin]
}
</script>

<style lang="scss" scoped>
.box-body {
  padding: 12px
}
.import-invoice-data strong {
  text-align: end;
}
.box-title{
    color: white;
    position: absolute;
    left: -3px;
    top: -11px;
    padding: 0 4px;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    letter-spacing: 0.8px;
}
.final {
  background-color: $errorColor;
}
.duplicated {
  background-color: $warningColor;
}
.final-duplicated {
  left: 139px;
  background-color: $warningColor !important
}
.current-invoice-data-row {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  }

.new-invoice-data-row {
  display: flex;
  flex-direction: column;
  padding-left: 50px
  }

.invoice-select-align {
  align-self: flex-end
  }
.current-invoice-data-column {
  margin-left:  10px;
  text-align: end
}
.import-invoice-number {
    padding-left: 25px;
    text-align: start;
    width: 13%
}
</style>
